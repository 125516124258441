import * as THREE from 'three'

const createLights = () => {

    const ambientLight = new THREE.AmbientLight(0xFFFFFF);
    ambientLight.position.set(1, 1, 0);

    const directionalLight = new THREE.DirectionalLight(0xFFFFFF, 1);
    directionalLight.position.set(1, 1, 0);

    return { ambientLight, directionalLight };

}

export { createLights }