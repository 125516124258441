const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);

const EARTH_MODEL_NOISE_SCALE = 2.1;
const ROCKET_MODEL_NOISE_SCALE = 1.3;
const DEFAULT_POINTER_DISPLACEMENT = Math.PI * 0.175;
const ROCKET_POINTER_DISPLACEMENT = isMobile ? Math.PI * 3.15 : Math.PI * 1.27;
const BASE_NOISE_MAGNITUDE = Math.PI * 0.15;

export const sections_details = [
    {
        id: 1,
        position: {
            x: 0,
            y: 0,
            z: 0
        },
        rotation: {
            x: 0,
            y: 0.5,
            z: 0
        },
        scale: isMobile ? 1.5 : 2.5,
        mix: 0,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: EARTH_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: DEFAULT_POINTER_DISPLACEMENT,
    },
    {
        id: 2,
        position: {
            x: isMobile ? 0.5 : 3,
            y: -.5,
            z: 1
        },
        rotation: {
            x: -0.5,
            y: 4,
            z: 0
        },
        scale: 0.5,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 3,
        position: {
            x: isMobile ? -0.25 : -1,
            y: -.5,
            z: 0
        },
        rotation: {
            x: 1,
            y: 4,
            z: 0
        },
        scale: 0.6,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 4,
        position: {
            x: isMobile ? 0.2 : 1,
            y: 1,
            z: 0
        },
        rotation: {
            x: 2,
            y: 0,
            z: 0
        },
        scale: 0.7,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 5,
        position: {
            x: isMobile ? -0.6 : -3,
            y: -.5,
            z: 0
        },
        rotation: {
            x: 0,
            y: 4,
            z: 0
        },
        scale: 0.6,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 6,
        position: {
            x: isMobile ? -0.4 : -2,
            y: -.5,
            z: 0
        },
        rotation: {
            x: 0,
            y: 0,
            z: -1
        },
        scale: 0.8,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 7,
        position: {
            x: 0,
            y: -.5,
            z: 0
        },
        rotation: {
            x: 1,
            y: 2,
            z: 0
        },
        scale: 0.6,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 8,
        position: {
            x: 0,
            y: -.5,
            z: 0
        },
        rotation: {
            x: 1,
            y: 0,
            z: 0
        },
        scale: 0.5,
        mix: 1,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: ROCKET_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: ROCKET_POINTER_DISPLACEMENT,
    },
    {
        id: 9,
        position: {
            x: 0,
            y: 0,
            z: 0
        },
        rotation: {
            x: 0,
            y: 0.5,
            z: 0
        },
        scale: isMobile ? 1.5 : 2.5,
        mix: 0,
        baseNoiseMagnitude: BASE_NOISE_MAGNITUDE,
        noiseScale: EARTH_MODEL_NOISE_SCALE,
        pointerDisplacementMagnitude: DEFAULT_POINTER_DISPLACEMENT,
    }
];

export const sections_count = sections_details.length;
