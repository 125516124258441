import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import Button from '../Button/Button';
import Magnetic from '../Utils/Magnetic';

const Header = () => {
    const [isTitleVisible, setIsTitleVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            // Adjust the threshold as needed
            if (scrollY >= window.innerHeight / 2 && isTitleVisible) {
                setIsTitleVisible(false);
            } else if (scrollY <= window.innerHeight / 2 && !isTitleVisible) {
                setIsTitleVisible(true);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isTitleVisible]);

    return (
        <header className={`${styles.header}`}>
            <div
                className={`${styles.header__background} ${isTitleVisible ? '' : styles.header__background__blur
                    }`}
            ></div>

            <div
                className={`${styles.header__wrapper} ${isTitleVisible ? '' : styles.header__wrapper__small
                    }`}
            >
                <Logo isTitleVisible={isTitleVisible} />

                <Menu />

                <Nav />
            </div>
        </header>
    );
};

const Logo = ({ isTitleVisible }) => {
    return (
        <Magnetic>
            <div className={styles.header__logo}>
                <img
                    className={`${styles.header__logo__img} ${isTitleVisible ? '' : styles.header__logo__img__big
                        }`}
                    src='./img/logo.svg'
                    alt='logo'
                />
                <h1
                    className={`${styles.header__logo__text} ${isTitleVisible ? '' : styles.header__logo__text__hide
                        }`}
                >
                    Starmax
                </h1>
            </div>
        </Magnetic>
    );
};

const Menu = () => {
    const [activeSection, setActiveSection] = useState('flight');
    const [progression, setProgression] = useState(0);
    const [underlinePosition, setUnderlinePosition] = useState(0);
    const modernity_start_section = 5;
    const consistency_start_section = 7;
    const last_section = 9;

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(`section-${sectionId}`);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const modernity =
            document.getElementById(`section-${modernity_start_section}`).offsetTop -
            1;
        const consistency =
            document.getElementById(`section-${consistency_start_section}`)
                .offsetTop - 1;
        const end =
            document.getElementById(`section-${last_section}`).offsetTop +
            document.getElementById('footer').offsetHeight -
            1;

        const handleSectionScroll = () => {
            // Get vertical scroll position
            const scrollPosition =
                window.scrollY || document.documentElement.scrollTop;

            // Define active section based on scroll position
            if (scrollPosition < modernity) {
                setActiveSection('flight');
            } else if (scrollPosition >= modernity && scrollPosition < consistency) {
                setActiveSection('modernity');
            } else {
                setActiveSection('consistency');
            }

            // Calculate progression based on scroll position
            if (activeSection === 'flight') {
                setProgression(scrollPosition / modernity);
            } else if (activeSection === 'modernity') {
                setProgression(
                    (scrollPosition - modernity) / (consistency - modernity)
                );
            } else {
                setProgression((scrollPosition - consistency) / (end - consistency));
            }

            setUnderlinePosition(progression * 100);
        };

        window.addEventListener('scroll', handleSectionScroll);

        return () => {
            window.removeEventListener('scroll', handleSectionScroll);
        };
    });

    return (
        <nav className={styles.header__menu}>
            <ul className={styles.header__menu__list}>
                <li
                    className={`${styles.header__menu__list__item}
                    ${activeSection === 'flight'
                            ? styles.header__menu__list__item__active
                            : ''
                        }`}
                    onClick={() => scrollToSection(1)}
                >
                    {/* Dynamic underline */}
                    <div
                        className={`${styles.header__menu__list__item__underline}
                        ${activeSection === 'flight'
                                ? styles.header__menu__list__item__underline__active
                                : ''
                            }`}
                        style={{
                            width: `${underlinePosition >= 100 ? 0 : underlinePosition}%`,
                        }}
                    ></div>
                    <p>
                        <span>01</span> Flight
                    </p>
                </li>
                <li
                    className={`${styles.header__menu__list__item}
                    ${activeSection === 'modernity'
                            ? styles.header__menu__list__item__active
                            : ''
                        }`}
                    onClick={() => scrollToSection(modernity_start_section)}
                >
                    {/* Dynamic underline */}
                    <div
                        className={`${styles.header__menu__list__item__underline}
                        ${activeSection === 'modernity'
                                ? styles.header__menu__list__item__underline__active
                                : ''
                            }`}
                        style={{
                            width: `${underlinePosition >= 100 ? 0 : underlinePosition}%`,
                        }}
                    ></div>
                    <p>
                        <span>02</span> Modernity
                    </p>
                </li>
                <li
                    className={`${styles.header__menu__list__item}
                    ${activeSection === 'consistency'
                            ? styles.header__menu__list__item__active
                            : ''
                        }`}
                    onClick={() => scrollToSection(consistency_start_section)}
                >
                    {/* Dynamic underline */}
                    <div
                        className={`${styles.header__menu__list__item__underline}
                        ${activeSection === 'consistency'
                                ? styles.header__menu__list__item__underline__active
                                : ''
                            }`}
                        style={{
                            width: `${underlinePosition >= 100 ? 0 : underlinePosition}%`,
                        }}
                    ></div>
                    <p>
                        <span>03</span> Consistency
                    </p>
                </li>
            </ul>
        </nav>
    );
};

const Nav = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
        document.body.style.overflow = isSidebarOpen ? 'hidden' : 'auto';
    };

    useEffect(() => {
        const handleBodyOverflow = () => {
            document.body.style.overflow = isSidebarOpen ? 'hidden' : 'auto';
        };

        handleBodyOverflow();

        // Cleanup function to reset body overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isSidebarOpen]);

    return (
        <>
            <nav className={styles.header__nav}>
                <ul className={styles.header__nav__list}>
                    <Magnetic>
                        <li
                            className={`${styles.header__nav__list__item} ${styles.header__nav__list__item__btn}`}
                        >
                            <Button
                                text='Book now'
                                color='transparent'
                                link='https://immersivestudios.de/'
                                target='_blank'
                            />
                        </li>
                    </Magnetic>
                    <Magnetic>
                        <li className={styles.header__nav__list__item}>
                            <div
                                className={styles.header__nav__list__item__menulogo}
                                onClick={toggleSidebar}
                            >
                                <div className={styles.header__nav__list__item__menulogo__line}>
                                    <div
                                        className={
                                            styles.header__nav__list__item__menulogo__line__item
                                        }
                                    ></div>
                                    <div
                                        className={
                                            styles.header__nav__list__item__menulogo__line__item
                                        }
                                    ></div>
                                </div>
                                <div className={styles.header__nav__list__item__menulogo__line}>
                                    <div
                                        className={
                                            styles.header__nav__list__item__menulogo__line__item
                                        }
                                    ></div>
                                    <div
                                        className={
                                            styles.header__nav__list__item__menulogo__line__item
                                        }
                                    ></div>
                                </div>
                            </div>
                        </li>
                    </Magnetic>
                </ul>
            </nav>

            <div
                className={`${styles.header__sidebar} ${isSidebarOpen ? styles.header__sidebar__open : ''
                    }`}
            >
                <div
                    className={styles.header__sidebar__close}
                    onClick={toggleSidebar}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1'
                        stroke='currentColor'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18 18 6M6 6l12 12'
                        />
                    </svg>
                </div>
                <div className={styles.header__sidebar__wrapper}>
                    <div className={styles.header__sidebar__content}>
                        <div className={styles.header__sidebar__content__logo}>
                            <img
                                className={`${styles.header__sidebar__content__logo__img}`}
                                src='./img/logo.svg'
                                alt='logo-credits'
                            />
                            <h1 className={`${styles.header__sidebar__content__logo__text}`}>
                                Starmax
                            </h1>
                        </div>

                        <p className={styles.header__sidebar__content__text}>
                            Starmax is a non-commercial showcase project by Immersive Studios
                            for the demonstration of scrollytelling techniques using particle
                            effects on the web.
                        </p>

                        <div className={styles.header__sidebar__content__credits}>
                            <h5 className={styles.header__sidebar__content__credits__title}>
                                TEAM CREDITS
                            </h5>

                            <div
                                className={styles.header__sidebar__content__credits__members}
                            >
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='https://jordigarreta.com'
                                    target='_blank'
                                >
                                    Jordi Garreta - Creative Development
                                </Link>
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='https://twitter.com/nikitayells'
                                    target='_blank'
                                >
                                    Nikita Agafonov - Creative Development
                                </Link>
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='https://immersivestudios.de/'
                                    target='_blank'
                                >
                                    Julius Branding - Creative Direction & UX/UI
                                </Link>
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='https://twitter.com/DamienToscano'
                                    target='_blank'
                                >
                                    Damien Toscano - Creative Development
                                </Link>
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='#'
                                    target='_blank'
                                >
                                    Melvin Romeijn - Front End Dev
                                </Link>
                                {/*                 <Link
                  className={
                    styles.header__sidebar__content__credits__members__item
                  }
                  to='https://nicolas-giannantonio.fr/'
                  target='_blank'
                >
                  Nicolas Giannantonio - Front End Dev
                </Link> */}
                                <Link
                                    className={
                                        styles.header__sidebar__content__credits__members__item
                                    }
                                    to='http://inexplicablecreations.space/'
                                    target='_blank'
                                >
                                    Roberto - 3D Design
                                </Link>
                            </div>
                        </div>

                        <div className={styles.header__sidebar__content__social}>
                            {/* TODO: Update links */}
                            <Magnetic>
                                <Link to='https://twitter.com/ImmersivStudios' target='_blank'>
                                    <img
                                        src='./img/socials/x.svg'
                                        alt='Twitter'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>
                            <Magnetic>
                                <Link to='https://www.youtube.com/@Immersive-Studios' target='_blank'>
                                    <img
                                        src='./img/socials/youtube.svg'
                                        alt='Youtube'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>
                            <Magnetic>
                                <Link to='https://www.linkedin.com/company/immersivstudios/' target='_blank'>
                                    <img
                                        src='./img/socials/linkedin.svg'
                                        alt='Linkedin'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>
                            <Magnetic>
                                <Link to='https://discord.com/invite/6EHbhmXx3K' target='_blank'>
                                    <img
                                        src='./img/socials/discord.svg'
                                        alt='Discord'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>

                            <Magnetic>
                                <Link to='https://www.awwwards.com/immersivestudios/' target='_blank'>
                                    <img
                                        src='./img/socials/awwwards.svg'
                                        alt='Awwwards'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>
                            <Magnetic>
                                <Link to='https://dribbble.com/immersivestudios' target='_blank'>
                                    <img
                                        src='./img/socials/dribble.svg'
                                        alt='Dribble'
                                        className={styles.header__sidebar__content__social__logo}
                                    />
                                </Link>
                            </Magnetic>
                        </div>

                        <div className={styles.header__sidebar__content__allrights}>
                            <p>© 2024 Immersive Studios. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
