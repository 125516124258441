import * as THREE from 'three'
import ScrollTrigger from 'gsap/ScrollTrigger'

async function loadShaders() {
    let result = await fetch('../shaders/stars_vertex.glsl');
    const starsVertex = await result.text();

    result = await fetch('../shaders/stars_fragment.glsl');
    const starsFragment = await result.text();

    result = await fetch('../shaders/fs_quad_fragment.glsl');
    const fsQuadFragment = await result.text();

    return {
        starsVertex,
        starsFragment,
        fsQuadFragment
    };
}

let gThreeParams;

const createStars = async (threeParams = {}, starsParams) => {
    gThreeParams = threeParams;
    const localScene = new THREE.Scene();
    const rtResolution = [window.innerWidth * starsParams.rtScale, window.innerHeight * starsParams.rtScale];

    const { starsVertex, starsFragment, fsQuadFragment } = await loadShaders();

    const starsRenderTarget = new THREE.WebGLRenderTarget(
        rtResolution[0],
        rtResolution[1],
        {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            type: THREE.UnsignedByteType
        }
    );

    const planeGeometry = new THREE.PlaneGeometry(
        2,
        2,
        1,
        1
    );

    const shaderMaterial = new THREE.ShaderMaterial({
        side: THREE.FrontSide,
        depthTest: false,
        uniforms: {
            uTime: { value: 0.0 },
            uScrollOffset: { value: 0.0 },
            uStarsDensity: { value: starsParams.uStarsDensity || 1.0 },
            uNebulaColor: { value: new THREE.Color(starsParams.uNebulaColor || 0x0000ff) },
            iResolution: { value: new THREE.Vector2(rtResolution[0], rtResolution[1]) }
        },
        vertexShader: starsVertex,
        fragmentShader: starsFragment,
    });

    const customBasicMaterial = new THREE.ShaderMaterial({
        side: THREE.FrontSide,
        depthTest: false,
        uniforms: {
            uMap: { value: starsRenderTarget.texture }
        },
        vertexShader: starsVertex,
        fragmentShader: fsQuadFragment
    });

    const starsBufferMesh = new THREE.Mesh(planeGeometry, shaderMaterial);
    localScene.add(starsBufferMesh);

    const stars = new THREE.Mesh(planeGeometry, customBasicMaterial);
    const clock = new THREE.Clock();

    // Animate starts on scroll
    ScrollTrigger.create({
        trigger: "#main",
        start: "top",
        end: "bottom",
        markers: false,
        onUpdate: self => {
           shaderMaterial.uniforms.uScrollOffset.value = self.progress * 20;
        }
    });

    const updateStars = () => {
        const elapsedTime = clock.getElapsedTime();
        shaderMaterial.uniforms.uTime.value = elapsedTime;
        gThreeParams.renderer.setRenderTarget(starsRenderTarget);
        gThreeParams.renderer.render(localScene, gThreeParams.camera);
        gThreeParams.renderer.setRenderTarget(null);
    }

    const handleResize = () => {
        starsBufferMesh.material.uniforms.iResolution.value.x = window.innerWidth * starsParams.rtScale;
        starsBufferMesh.material.uniforms.iResolution.value.y = window.innerHeight * starsParams.rtScale;
        starsRenderTarget.setSize(window.innerWidth * starsParams.rtScale, window.innerHeight * starsParams.rtScale);
    }

    return { stars, starsBufferMesh, updateStars, handleResize }
}

export { createStars }
