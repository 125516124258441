import React, { useEffect, useRef } from 'react'
import gsap from 'gsap';

const Magnetic = ({ children }) => {
    const magnetic = useRef(null);

    useEffect(() => {
        const currentMagnetic = magnetic.current;
        const xTo = gsap.quickTo(currentMagnetic, "x", { duration: 1, ease: "elastic.out(1, 0.3)" })
        const yTo = gsap.quickTo(currentMagnetic, "y", { duration: 1, ease: "elastic.out(1, 0.3)" })

        const mouseMove = (e) => {
            const { clientX, clientY } = e;
            const { height, width, left, top } = currentMagnetic.getBoundingClientRect();
            const x = clientX - (left + width / 2)
            const y = clientY - (top + height / 2)

            xTo(x);
            yTo(y)
        }

        const mouseLeave = (e) => {
            gsap.to(currentMagnetic, { x: 0, duration: 0.2 })
            gsap.to(currentMagnetic, { y: 0, duration: 0.2 })

            xTo(0);
            yTo(0)
        }

        currentMagnetic.addEventListener("mousemove", mouseMove)
        currentMagnetic.addEventListener("mouseleave", mouseLeave)

        return () => {
            currentMagnetic.removeEventListener("mousemove", mouseMove)
            currentMagnetic.removeEventListener("mouseleave", mouseLeave)
        }
    }, [])

    return (
        React.cloneElement(children, { ref: magnetic })
    )
};

export default Magnetic;