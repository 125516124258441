import styles from './App.module.scss';

import { Outlet } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';

function App() {
    return (
        <>
            <Loader />
            <Header />
            <div className={styles.app}>
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default App;
