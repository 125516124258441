import styles from './Button.module.scss';
import { Link } from 'react-router-dom';
import Magnetic from '../Utils/Magnetic';

const Button = ({ text, color, link, target = '_self' }) => {
    return (
        <Magnetic>
            <Link className={styles.button__link} to={link} target={target}>
                <div
                    className={`${styles.button} ${color === 'white' && styles.button__white
                        } `}
                >
                    {text}
                </div>
            </Link>
        </Magnetic>
    );
};

export default Button;
