import styles from './NoPage.module.scss'

const NoPage = () => {
    return (
        <div className={styles.nopage}>
            no page
        </div>
    )
}

export default NoPage