import React, { useEffect, useRef } from 'react';
import styles from './Scene.module.scss';
import * as THREE from 'three';
import * as dat from 'lil-gui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

import { createLights } from './components/lights';
import * as GPUParticles from './components/gpuparticles';

import { createStars } from './components/stars';

import Stats from 'three/examples/jsm/libs/stats.module';
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';

const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(navigator.userAgent);

const DEBUG = window.location.hash.indexOf("debug") > -1;
let gParticlesMaterial;
let gPointsMesh;
let gStarsProgram;

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('draco/');

// GLTF loader
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

// Load assets.
const modelAssets = [
    "models/earth.glb",
    "models/rocket1v2.glb"
];
const loadedGlbs = [];
let loadedGlbsCount = 0;

// Use that to dispatch an event to the UI
const updateLoadingProgress = (progress) => {
    const event = new CustomEvent('loadingProgress', { detail: { progress } });
    window.dispatchEvent(event);
};

function loadModelAssets() {
    const loadingPromises = [];

    const loadModel = (i) => {
        let resolver;
        const p = new Promise((res, rej) => {
            resolver = res;
        });

        // Setting promises and glbs using index to ensure correctness of models order.
        // In this case order is going to match modelAssets list.
        loadingPromises[i] = p;

        gltfLoader.load(modelAssets[i], (glbModel) => {
            loadedGlbs[i] = glbModel;
            loadedGlbsCount++;

            // Save it in a store to retrieve it in the UI
            const progress = loadedGlbsCount / modelAssets.length * 100;
            updateLoadingProgress(progress);

            resolver();
        });
    }

    for (let i = 0; i < modelAssets.length; i++) {
        loadModel(i);
    }

    return loadingPromises;
}

const modelsLoadingPromises = loadModelAssets();

if (DEBUG) {
    const gui = new dat.GUI({ width: 400 });
    const starsgui = gui.addFolder("stars");

    const starsDebugObject = {
        uNebulaColor: 0x2a0e77,
        uStarsDensity: 0.5
    };

    starsgui.add(starsDebugObject, "uStarsDensity", 0, 1).onChange(() => {
        gStarsProgram.starsBufferMesh.material.uniforms.uStarsDensity.value = starsDebugObject.uStarsDensity;
    });

    starsgui.addColor(starsDebugObject, 'uNebulaColor').onChange(() => {
        gStarsProgram.starsBufferMesh.material.uniforms.uNebulaColor.value.set(
            starsDebugObject.uNebulaColor
        );
    });

    const particlesDebugObject = {
        particleStartColor: isMobile ? 0x401871 : 0x3c355f,
        particleEndColor: isMobile ? 0x2C565E : 0x343555,
        particleTouchColor: 0xa46652,
        particleLifetime: 0.4,
        spawnPointMix: 0,
        pointerDisplacementMag: Math.PI * 1.15,
        noiseScale: 1.3,
        noiseMagnitude: Math.PI * 0.075,
        modelPositionX: 0,
        modelPositionY: 0,
        modelPositionZ: 0,
        modelScale: 1.,
        modelRotationX: 0,
        modelRotationY: 0,
        modelRotationZ: 0,
    };

    const particlesgui = gui.addFolder("particles");

    particlesgui.add(particlesDebugObject, 'particleLifetime', .01, 7).onChange((v) => {
        gParticlesMaterial.simShaderMaterial.uniforms.uParticlesLifetime.value = v;
        gParticlesMaterial.pointsRenderShaderMaterial.uniforms.uParticlesLifetime.value = v;
    });

    particlesgui.add(particlesDebugObject, 'spawnPointMix', 0, 1, .001).onChange((v) => {
        gParticlesMaterial.simShaderMaterial.uniforms.uOriginPointMix.value = v;
    });

    particlesgui.add(particlesDebugObject, 'pointerDisplacementMag', 0, 10, .001).onChange((v) => {
        gParticlesMaterial.simShaderMaterial.uniforms.uPointerDisplacementMagnitude.value = v;
    });

    particlesgui.add(particlesDebugObject, 'noiseScale', 0, 10, .1).onChange((v) => {
        gParticlesMaterial.simShaderMaterial.uniforms.uNoiseScale.value = v;
    });

    particlesgui.add(particlesDebugObject, 'noiseMagnitude', 0, 10, .001).onChange((v) => {
        gParticlesMaterial.simShaderMaterial.uniforms.uNoiseMagnitude.value = v;
    });

    particlesgui.addColor(particlesDebugObject, 'particleStartColor').onChange(() => {
        gParticlesMaterial.pointsRenderShaderMaterial.uniforms.uParticleStartColor.value.set(
            particlesDebugObject.particleStartColor
        );
    });

    particlesgui.addColor(particlesDebugObject, 'particleEndColor').onChange(() => {
        gParticlesMaterial.pointsRenderShaderMaterial.uniforms.uParticleEndColor.value.set(
            particlesDebugObject.particleEndColor
        );
    });

    particlesgui.addColor(particlesDebugObject, 'particleTouchColor').onChange(() => {
        gParticlesMaterial.pointsRenderShaderMaterial.uniforms.uParticleTouchColor.value.set(
            particlesDebugObject.particleTouchColor
        );
    });

    particlesgui.add(particlesDebugObject, 'modelScale', 0, 10, .001).onChange((v) => {
        gPointsMesh.scale.set(v, v, v);
    });

    particlesgui.add(particlesDebugObject, 'modelPositionX', -10, 10, .001).onChange((v) => {
        gPointsMesh.position.setX(v);
    });

    particlesgui.add(particlesDebugObject, 'modelPositionY', -10, 10, .001).onChange((v) => {
        gPointsMesh.position.setY(v);
    });

    particlesgui.add(particlesDebugObject, 'modelPositionZ', -1, 10, .001).onChange((v) => {
        gPointsMesh.position.setZ(v);
    });

    particlesgui.add(particlesDebugObject, 'modelRotationX', -Math.PI, Math.PI, .001).onChange((v) => {
        gPointsMesh.rotation.x = v;
    });

    particlesgui.add(particlesDebugObject, 'modelRotationY', -Math.PI, Math.PI, .001).onChange((v) => {
        gPointsMesh.rotation.y = v;
    });

    particlesgui.add(particlesDebugObject, 'modelRotationZ', -Math.PI, Math.PI, .001).onChange((v) => {
        gPointsMesh.rotation.z = v;
    });

}

const Scene = () => {
    const canvasRef = useRef();

    var camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );
    var renderer = new THREE.WebGLRenderer();

    // Camera init animation
    useGSAP(() => {
        gsap.fromTo(camera.position, {
            // TODO: Tweaks values for init camera animation
            z: 3,
        }, {
            // TODO: Tweaks values for init camera animation
            z: 5,
            duration: 5,
            ease: "power2.out",
        }, { scope: canvasRef });
    });

    useEffect(() => {
        console.log('** Create ThreeJS Scene');
        // === THREE.JS CODE START ===
        var scene = new THREE.Scene();
        const pointer = new THREE.Vector2();

        renderer.setClearColor(0x000000, 1);
        renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
        renderer.setSize(window.innerWidth, window.innerHeight, false);
        canvasRef.current.appendChild(renderer.domElement);

        // === Stats ===
        const stats = new Stats();
        if (DEBUG) {
            document.body.appendChild(stats.dom);
        }

        // === Lights ===
        const { ambientLight, directionalLight } = createLights();
        scene.add(ambientLight);
        scene.add(directionalLight);

        // === Stars ===
        (async () => {
            const starsProgram = await createStars({
                scene,
                camera,
                renderer
            },
                {
                    rtScale: 0.5,
                    uNebulaColor: 0x2a0e77,
                    uStarsDensity: 0.343
                });

            scene.add(starsProgram.stars);

            gStarsProgram = starsProgram;
        })();
        // === Add meshes ===

        // === Particles ===
        (async () => {
            await Promise.all(modelsLoadingPromises);

            const particlesAmount = isMobile ? 256 : 512

            const { pointsMesh, materials } = await GPUParticles.init(
                {
                    scene,
                    camera,
                    renderer,
                },
                {
                    width: particlesAmount,
                    height: particlesAmount,
                    glbModels: loadedGlbs
                }
            );

            // TODO: add methods for setting various parameters to GPUParticles module.
            materials.simShaderMaterial.uniforms.uParticlesLifetime.value = 0.95064;
            materials.pointsRenderShaderMaterial.uniforms.uParticlesLifetime.value = 0.95064;
            materials.simShaderMaterial.uniforms.uNoiseScale.value = 2.1;
            materials.simShaderMaterial.uniforms.uNoiseMagnitude.value = Math.PI * 0.075;
            materials.pointsRenderShaderMaterial.uniforms.uParticleStartColor.value.set(0x3c355f);
            materials.pointsRenderShaderMaterial.uniforms.uParticleEndColor.value.set(0x343555);
            materials.pointsRenderShaderMaterial.uniforms.uParticleTouchColor.value.set(0xa46652);

            scene.add(pointsMesh);
            gParticlesMaterial = materials;
            gPointsMesh = pointsMesh;
        }
        )();

        // === Animation function ===
        const animate = () => {
            requestAnimationFrame(animate);

            if (gStarsProgram) {
                gStarsProgram.updateStars();
            }
            GPUParticles.update();

            // Update Stats
            stats.update();

            // Render the scene
            renderer.render(scene, camera);
        };

        // Start the animation
        animate();

        // Event listener for window resize
        const handleResize = () => {
            const newAspest = window.innerWidth / window.innerHeight;
            camera.aspect = newAspest;
            camera.updateProjectionMatrix();
            gStarsProgram.handleResize();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        const handlePointermove = (e) => {
            pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
            pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;

            // Update camera position on mouse move
            camera.position.x = pointer.x * 0.5;
            camera.position.y = pointer.y * 0.5;
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('pointermove', handlePointermove);

        // Cleanup function to remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('pointermove', handlePointermove);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to run the effect only once

    return <div className={styles.scene} ref={canvasRef} />;
};

export default Scene;
