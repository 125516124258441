import React from 'react';
import ReactDOM from 'react-dom/client';
import {  Routes, Route, HashRouter } from "react-router-dom"; /* BrowserRouter, */
import './index.scss';
import App from './App';
import Home from './pages/Home/Home';
import NoPage from './pages/NoPage/NoPage';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
