import { useEffect, useRef, useState } from 'react';
import styles from './Loader.module.scss';
import gsap from 'gsap';

const Loader = () => {
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [displayLaunchText, setDisplayLaunchText] = useState(false);
    const loaderContainer = useRef();
    const progressBar = useRef();

    useEffect(() => {
        window.addEventListener('loadingProgress', handleLoadingProgress);

        return () => {
            window.removeEventListener('loadingProgress', handleLoadingProgress);
        }
    }, []);

    const handleLoadingProgress = (event) => {
        const event_progress = event.detail.progress;
        setProgress(event_progress);

        if (event_progress === 100) {
            gsap.to(loaderContainer.current, {
                delay: 1,
                duration: 1.5,
                opacity: 0,
                onStart: () => {
                    setDisplayLaunchText(true);
                },
                onComplete: () => {
                    setIsLoading(false);
                }
            });
        }
    };

    return (
        isLoading && (
            <div ref={loaderContainer} className={styles.loader}
                style={{ backgroundImage: `url('./img/background-loader.png')` }}
            >
                <div className={styles.loader__top}>
                    <div className={styles.loader__top__logo}>
                        <img
                            className={styles.loader__top__logo__img}
                            src='./img/logo.svg'
                            alt='logo'
                        />
                        <h1 className={styles.loader__top__logo__text}>
                            Starmax
                        </h1>
                    </div>
                </div>
                <div className={styles.loader__main}>
                    <div className={styles.loader__main__progress}>
                        <div ref={progressBar} className={styles.loader__main__progress__active} style={{ width: `${progress}%` }}>
                            <img
                                className={styles.loader__main__progress__active__logo}
                                src='./img/logo.svg'
                                alt='logo'
                            />
                        </div>
                    </div>
                    <p className={styles.loader__main__info}>
                        {!displayLaunchText && 'Preparing for launch...'}
                        {displayLaunchText && 'Ready for launch'}
                    </p>
                </div>
                <div className={styles.loader__bottom}>
                    <img className={styles.loader__bottom__shape} src='./img/background-ring.png' alt='shape' />
                </div>
            </div>
        ));
};

export default Loader;