import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer id='footer' className={styles.footer}>
            <div className={styles.footer__wrapper}>

                <div className={styles.footer__left}>
                    <p>©2024</p>
                </div>

                <div className={styles.footer__center}>
                    <p>Book flights at $9,999 (including tax). Commercial flights available. For more information visit starmax.com/prices</p>
                </div>

                <div className={styles.footer__right}>
                    <p>
                        Starmax LLC
                    </p>
                </div>

                <div className={styles.footer__bottom}>
                    <p>©2024 Starmax LLC</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
