import styles from './Home.module.scss'

import Scene from '../../components/Scene/Scene'
import Button from '../../components/Button/Button'
import Magnetic from '../../components/Utils/Magnetic';

import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from "@gsap/react";

import { useEffect, useRef } from 'react'

gsap.registerPlugin(ScrollTrigger)

const ScrollDown = ({ sectionId }) => {
    const container = useRef();
    const arrow = useRef();

    const scrollNextSection = () => {
        const next_section = document.getElementById(`section-${sectionId}`).nextElementSibling

        if (next_section) {
            next_section.scrollIntoView({ behavior: "smooth" })
        }
    }

    useGSAP(() => {
        gsap.fromTo(arrow.current, {
            y: -10,
        }, {
            y: 10,
            repeat: -1,
            yoyo: true,
            duration: 1,
        });
    }, { scope: container });

    return (
        <Magnetic>
            <div ref={container} className={styles.home__scrolldown} onClick={scrollNextSection}>
                <img ref={arrow} className={styles.home__scrolldown__img} src='./img/scrolldown.svg' alt='scrolldown' />
                <p className={styles.home__scrolldown__text}>Scroll down</p>
            </div>
        </Magnetic>
    )
}

const StageHome = ({ sectionId, subheadline, title1, title2 }) => {
    const container = useRef();
    const subheadlineRef = useRef();
    const titleRef = useRef();
    const tl = gsap.timeline();

    useGSAP(() => {
        tl.fromTo(subheadlineRef.current, { x: -100, opacity: 0 }, { x: 0, opacity: 1, duration: 0.6, delay: 0.2, ease: "power2.out" })
            .fromTo(titleRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1, delay: -0.4, ease: "power2.out" });
    }, { scope: container });

    return (
        <section ref={container} id={`section-${sectionId}`} className={`section ${styles.home__section}`}>
            <div className={styles.home__section__stagehome}>
                <div className={styles.home__section__stagehome__wrapper}>
                    <p ref={subheadlineRef} className={styles.home__section__stagehome__subheadline}>{subheadline}</p>
                    <div ref={titleRef} className={styles.home__section__stagehome__headline}>
                        <h1 className={styles.home__section__stagehome__headline__title}>{title1}</h1>
                        <h1 className={styles.home__section__stagehome__headline__subtitle}>{title2}</h1>
                    </div>
                </div>
            </div>

            <ScrollDown sectionId={sectionId} />

        </section>
    )
}

const StageText = ({ sectionId, subheadline, title, side }) => {
    const container = useRef();
    const subheadlineRef = useRef();
    const titleRef = useRef();

    useGSAP(() => {
        gsap.from(subheadlineRef.current,
            {
                y: 100,
                opacity: 0.1,
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 50%",
                    end: "bottom 100%",
                    markers: false,
                    scrub: 1,
                }
            }
        );

        gsap.from(titleRef.current,
            {
                y: 230,
                opacity: 0.1,
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 50%",
                    end: "bottom 100%",
                    markers: false,
                    scrub: 1
                }
            }
        );
    }, { scope: container });


    return (
        <section ref={container} id={`section-${sectionId}`} className={`section ${styles.home__section}`}>
            <div className={`${styles.home__section__stagetext} ${side === 'right' ? styles.home__section__stagetext__right : ""}`}>
                <div className={styles.home__section__stagetext__wrapper}>
                    <p ref={subheadlineRef} className={styles.home__section__stagetext__subheadline}>{subheadline}</p>
                    <div ref={titleRef} className={styles.home__section__stagetext__headline}>
                        <h2 className={styles.home__section__stagetext__headline__title}>{title}</h2>
                    </div>
                </div>
            </div>

            <ScrollDown sectionId={sectionId} />

        </section>
    )
}

const StageCenter = ({ sectionId, img, title, text, subtext }) => {
    const container = useRef();
    const textRef = useRef();
    const titleRef = useRef();

    useGSAP(() => {
        gsap.from(textRef.current,
            {
                y: -100,
                opacity: 0.5,
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 50%",
                    end: "bottom 100%",
                    markers: false,
                    scrub: 1
                }
            }
        );
        gsap.from(titleRef.current,
            {
                y: 100,
                opacity: 0.5,
                scrollTrigger: {
                    trigger: container.current,
                    start: "top 50%",
                    end: "bottom 100%",
                    markers: false,
                    scrub: 1
                }
            }
        );
    }, { scope: container });

    return (
        <section ref={container} id={`section-${sectionId}`} className={`section ${styles.home__section}`}>
            <div className={styles.home__section__stagecenter}>
                <div className={styles.home__section__stagecenter__wrapper}>
                    <p ref={textRef} className={styles.home__section__stagecenter__text}>{text}</p>
                    <h2 ref={titleRef} className={styles.home__section__stagecenter__title}>{title}</h2>
                </div>
            </div>

            <ScrollDown sectionId={sectionId} />

        </section>
    )
}

const StageButtons = ({ sectionId, title }) => {
    const container = useRef();
    const buttons = useRef();

    useGSAP(() => {
        gsap.from(buttons.current.children, {
            y: 100,
            opacity: 0.5,
            stagger: 0.2,
            scrollTrigger: {
                trigger: container.current,
                start: "top 25%",
                end: "bottom 100%",
                markers: false,
                scrub: 1
            }
        });


    }, { scope: container });

    return (
        <section ref={container} id={`section-${sectionId}`} className={`section ${styles.home__section}`}>
            <div className={styles.home__section__stagebuttons}>
                <div className={styles.home__section__stagebuttons__wrapper}>
                    <h2 className={styles.home__section__stagebuttons__title}>{title}</h2>

                    <div ref={buttons} className={styles.home__section__stagebuttons__btns}>
                        {/* TODO: Update links */}
                            <Button text="Book now" color="white" link="/" />
                            <Button text="View Starmax" color="black" link="/" />
                    </div>

                </div>
            </div>
        </section>
    )
}

const Home = () => {

    useEffect(() => {
        const lenis = new Lenis()

        lenis.on('scroll', (e) => {
            // console.log(e)
        })

        lenis.on('scroll', ScrollTrigger.update)

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000)
        })

        gsap.ticker.lagSmoothing(0)
    }, [])

    return (
        <div id='main' className={styles.home}>

            <Scene />

            <div id='smooth-wrapper'>
                <div id='smooth-content'>

                    <StageHome sectionId="1" subheadline="STARMAX COMMERCIAL FLIGHTS" title1="EARTH TO EARTH" title2="TRANSPORTATION" />

                    <StageText sectionId="2" subheadline="SPACEMAX DEPARTURES AT 7AM LOCAL TIME" title="ONBOARDING NYC - SHANGHAI" side="left" />

                    <StageText sectionId="3" subheadline="ULTRASONIC TRAVEL EXPERIENCE" title="MAX SPEED 27,000 KM/H" side="right" />

                    <StageText sectionId="4" subheadline="FROM NYC TO SHANGHAI" title="39 MIN AVERAGE FLIGHT TIME" side="left" />

                    <StageText sectionId="5" subheadline="STARMAX LANDS AT 8:39 PM" title="ARRIVAL IN SHANGHAI" side="right" />

                    <StageCenter sectionId="6" title="LONDON - DUBAI IN 29 MIN" text="FLY LONG TERM TRIPS" />

                    <StageCenter sectionId="7" title="LAX - TORONTO IN 26 MIN" text="FLY SHORT TERM TRIPS" />

                    <StageCenter sectionId="8" title="MOST LONG TERM TRIPS IN < 30 MIN" text="FLY EARTH TO EARTH" />

                    <StageButtons sectionId="9" title="ANYWHERE ON EARTH IN UNDER AN HOUR" />

                </div>
            </div>
        </div>
    )
}

export default Home